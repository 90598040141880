<!--
 * @Author: hwu
 * @Date: 2020-08-28 12:16:31
 * @Description: 菜谱页面 -> 主页面
 * @LastEditTime: 2024-04-10 16:23:08
-->
<template>
  <div class="app-container">
    <menu-content with-footer />
    <!-- 购物车底部栏 -->
    <menu-cart-footer />
  </div>
</template>
<script>
import MenuContent from './MenuContent'
import MenuCartFooter from './MenuCartFooter'
export default {
  components: {
    MenuContent,
    MenuCartFooter
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped></style>
